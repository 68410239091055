/* exported pxlAccordion */
class pxlAccordion {
  constructor( selector ) {
    const $ = document.querySelector(selector);
    
    $.addEventListener('click', function(e) {
      if ( e.target && e.target.classList.contains('acc-title') ) {
        let p = e.target.parentNode;
        
        if ( p.classList.contains('open') ) p.classList.remove('open');
        else {
          if ( this.classList.contains('automatic') ) {
            this.querySelectorAll('.acc-item.open').forEach(function( item ) {
              if ( item != e.target ) item.classList.remove('open');
            });
          }
          p.classList.add('open');
        }
      }
    }, true);
  }
}